//import logo from './logo.svg';
import './App.css';
import React from 'react';
import Navbar from './components/Nav.jsx';
import Hero from './components/HeroPage.jsx';
import StatementOfFaith from './components/SOF.jsx';
import AboutPage from './components/AboutPage.jsx';
import ContactPage from './components/Contact.jsx';
import EventsSection from './components/Events.jsx';
import Footer from './components/Footer.jsx';

const App = () => {
  return (
    <div className="bg-gray-900 text-gray-200">
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <Hero />

      {/* Statement of Faith Section */}
      <StatementOfFaith />

      {/* About Section */}
      <AboutPage />

      {/* Contact Section */}
      <EventsSection />

      {/* Contact Section */}
      <Footer />

      {/* Contact Section */}
      <ContactPage />
    </div>
  );
};

export default App;



