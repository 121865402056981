import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Hana from "../Assets/Hana.jpg";
import G1 from "../Assets/G1.jpg";
import G2 from "../Assets/G2.jpg";
import G3 from "../Assets/G3.jpg";
import G4 from "../Assets/G4.jpg";
import G5 from "../Assets/G5.jpg";
import G6 from "../Assets/G6.jpg";
import G7 from "../Assets/G7.jpg";

// Array of images
const images = [Hana, G1, G2, G3, G4, G5, G6, G7];

const PhotoGallery = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const scrollRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const NextArrow = ({ onClick }) => (
    <button
      className="absolute right-4 top-1/2 -translate-y-1/2 bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-3 z-10 transition-all duration-300 shadow-lg"
      onClick={onClick}
    >
      <ChevronRight className="w-6 h-6 text-white" />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button
      className="absolute left-4 top-1/2 -translate-y-1/2 bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-3 z-10 transition-all duration-300 shadow-lg"
      onClick={onClick}
    >
      <ChevronLeft className="w-6 h-6 text-white" />
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false,
        }
      }
    ]
  };

  const MobileGallery = () => (
    <div className="px-4 sm:px-6">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-1">
            <div className="relative">
              <img 
                src={image} 
                alt={`Worship ${index + 1}`} 
                className="w-full h-64 sm:h-80 object-cover rounded-lg transition-transform duration-300"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );

  const DesktopGallery = () => {
    useEffect(() => {
      const scrollContainer = scrollRef.current;
      if (!scrollContainer) return;

      const scrollWidth = scrollContainer.scrollWidth;

      let scrollPosition = 0;
      let direction = 1; // 1 for right-to-left, -1 for left-to-right
      const scrollSpeed = 0.4; // Adjust this value to change scroll speed

      const scroll = () => {
        scrollPosition += scrollSpeed * direction;
        
        if (scrollPosition >= scrollWidth / 3) {
          scrollPosition -= scrollWidth / 3;
        } else if (scrollPosition <= 0) {
          scrollPosition += scrollWidth / 3;
        }

        scrollContainer.scrollLeft = scrollPosition;
        requestAnimationFrame(scroll);
      };

      const animationFrame = requestAnimationFrame(scroll);

      return () => cancelAnimationFrame(animationFrame);
    }, []);

    return (
      <div className="overflow-hidden" ref={scrollRef}>
        <div className="flex">
          {[...images, ...images, ...images].map((image, index) => (
            <div 
              key={index} 
              className="relative overflow-hidden rounded-lg shadow-lg mx-2 flex-shrink-0 transition-all duration-300 hover:scale-105 hover:shadow-2xl group"
            >
              <img 
                src={image} 
                alt={`Worship ${(index % images.length) + 1}`} 
                className="w-80 h-52 xl:w-96 xl:h-64 object-cover rounded-lg"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"></div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <section id="gallery" className="py-16">
      <div className="max-w-7xl mx-auto">
        {isMobile ? <MobileGallery /> : <DesktopGallery />}
      </div>
      <style>{`
        .slick-dots li button:before {
          color: white !important;
          font-size: 12px !important;
          opacity: 0.5;
          transition: all 0.3s ease;
        }
        .slick-dots li.slick-active button:before {
          color: white !important;
          opacity: 1;
          transform: scale(1.2);
        }
      `}</style>
    </section>
  );
};

export default PhotoGallery;