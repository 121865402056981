import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Book, ChevronDown } from 'lucide-react';

const StatementOfFaith = () => {
  const [showAll, setShowAll] = useState(false);

  const beliefs = [
    {
      title: "Infallibility of Scripture",
      text: "We believe all 66 books making up the Old and New Testaments are infallible and inspired by God. They are of supreme and final authority in matters of faith and life.",
      scriptures: ["II Tim. 3:16", "II Peter 1:19-2", "Isaiah 40:8", "Matthew 24:35"],
    },
    {
      title: "The Nature of God",
      text: "We believe in one God, eternally existing in three persons: Father, Son and Holy Spirit.",
      scriptures: ["I Timothy 2:5", "I Corinthians 8:6", "Exodus 20:2-3", "Deuteronomy 6:4", "John 14:16, 18,23,26", "I John 5:7"],
    },
    {
      title: "The Incarnation of Christ",
      text: "We believe that Jesus Christ was begotten by the Holy Spirit, was born of the Virgin Mary, and is both true God and true man.",
      scriptures: ["Matthew 1:18, 23-25", "Luke 1:35", "I Timothy 2:5", "Philippians 2:6-8"],
    },
    {
      title: "Atonement through Christ",
      text: "We believe that the Lord Jesus Christ died for our sins, according to the Scriptures, as a representative and substitutionary sacrifice.",
      scriptures: ["Romans 3:24-26", "Galatians 2:16", "Romans 5:1,9", "I Peter 2:24", "I Corinthians 15:3"],
    },
    {
      title: "Resurrection and Ascension",
      text: "We believe in the resurrection of the crucified body of our Lord Jesus Christ, in His ascension into Heaven, and in His present life there for us.",
      scriptures: ["John 11:25", "Philippians 3:10", "Matthew 28:6-7", "Acts 1:9", "I Corinthians 15:4", "Hebrews 4:14-15", "I John 2:1"],
    },
    {
      title: "Human Sinfulness",
      text: "We believe that man was created in the image of God; that he sinned and thereby incurred, not only physical death, but also that spiritual death which is separation from God.",
      scriptures: ["Genesis 1:26-27", "Romans 3:23", "I John 1:8, 10", "Psalms 51:5"],
    },
    {
      title: "New Birth",
      text: "We believe that all who receive by faith the Lord Jesus Christ are born again of the Holy Spirit and thereby become children of God.",
      scriptures: ["John 3:3-5", "II Corinthians 5:17", "Ephesians 2:1-10", "II Peter 1:4", "Titus 3:5-7", "John 1:12-13"],
    },
    {
      title: "The Church",
      text: "We believe in the Church in its universal aspect comprising the whole body of those who have been born of the Spirit; and its local expression established for mutual edification and witness.",
      scriptures: ["Ephesians 1:22-23", "Ephesians 5:25-32", "Acts 15:41", "Acts 16:5"],
    },
    {
      title: "Baptism and Communion",
      text: "We believe in the baptism of believers by immersion and the celebration of the Lord’s supper.",
      scriptures: ["Acts 22:16", "Matthew 28:19-20", "I Corinthians 11:23-26", "Acts 8:38", "Matthew 3:16"],
    },
    {
      title: "The Holy Spirit",
      text: "We believe in the Holy Spirit, the Baptism of the Holy Spirit and the gifts of the Holy Spirit.",
      scriptures: ["Joel 2:28-32", "Acts 2", "Ephesians 5:18", "1 Corinthians 12-14"],
    },
    {
      title: "Victorious Christian Living",
      text: "We believe in the Spirit-filled life for victorious Christian living and effective service, and in worldwide evangelization through missionary activity.",
      scriptures: ["Ephesians 5:18", "Acts 6:3", "Matthew 28:19", "Acts 1:8", "John 10:27-29", "Ephesians 1:13-14"],
    },
    {
      title: "The Existence of Evil",
      text: "We believe in the presence of Satan, who is also called the devil. He, with his demons, is in continual warfare with the workings of God.",
      scriptures: ["Matthew 4:1-11", "Matthew 25:31", "Revelation 20:2, 10", "II Corinthians 11:3"],
    },
    {
      title: "Eternal State",
      text: "We believe in the bodily resurrection of the just and the unjust, the eternal blessedness of the saved and the everlasting punishment of the lost.",
      scriptures: ["I Corinthians 15:21-23", "II Thessalonians 1:7-10", "Revelation 20:11-15", "Matthew 25:34"],
    },
    {
      title: "The Second Coming",
      text: "We believe in the imminent return of our Lord and Savior Jesus Christ.",
      scriptures: ["John 14:3", "Acts 1:19-21", "I Thessalonians 4:16-17", "James 5:8", "Hebrews 9:28", "Titus 2:13"],
    },
  ];

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <section id="faith" className="py-16 bg-gradient-to-br from-gray-900 to-gray-800 overflow-hidden">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-5xl font-bold text-center text-white mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Our Core Beliefs
        </motion.h2>
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {beliefs.slice(0, showAll ? beliefs.length : 6).map((belief, index) => (
            <motion.div 
              key={index} 
              className="bg-gray-800 p-6 rounded-xl shadow-lg"
              variants={item}
              whileHover={{ 
                scale: 1.05, 
                boxShadow: "0 0 20px rgba(0, 255, 255, 0.3)",
                transition: { duration: 0.3 }
              }}
            >
              <motion.div 
                className="flex items-center mb-3"
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <Book className="text-teal-400 mr-2" size={24} />
                <h3 className="text-2xl font-semibold text-teal-400">{belief.title}</h3>
              </motion.div>
              <p className="text-gray-300 text-sm mb-4">{belief.text}</p>
              <div className="flex flex-wrap gap-2">
                {belief.scriptures.map((scripture, i) => (
                  <motion.span 
                    key={i} 
                    className="bg-teal-600 text-gray-100 text-xs px-2 py-1 rounded-full"
                    whileHover={{ scale: 1.1, backgroundColor: "#2dd4bf" }}
                  >
                    {scripture}
                  </motion.span>
                ))}
              </div>
            </motion.div>
          ))}
        </motion.div>
        <motion.div 
          className="flex justify-center mt-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          {!showAll && (
            <motion.button
              onClick={() => setShowAll(true)}
              className="px-6 py-3 bg-teal-600 text-white text-lg font-semibold rounded-full flex items-center transition duration-300 focus:outline-none focus:ring-2 focus:ring-teal-400 focus:ring-opacity-50"
              whileHover={{ scale: 1.05, backgroundColor: "#2dd4bf" }}
              whileTap={{ scale: 0.95 }}
            >
              See More
              <ChevronDown className="ml-2" size={20} />
            </motion.button>
          )}
        </motion.div>
      </div>
    </section>
  );
};

export default StatementOfFaith;