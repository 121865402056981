import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Video, Users, Clock } from 'lucide-react';

const EventsSection = () => {
  const events = [
    { title: "Sunday Service", time: "12:45 - 14:45", day: "Every Sunday", type: "in-person" },
    { title: "Tuesday Prayer", time: "20:30 - 22:00", day: "Every Tuesday", type: "zoom" },
    { title: "Saturday Bible Study", time: "20:00 - 22:00", day: "Every Saturday", type: "zoom" },
  ];

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <section id="events" className="py-20 bg-gradient-to-br from-gray-900 to-gray-800 text-white mb-16 rounded-b-3xl">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-5xl font-bold text-center text-white mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Upcoming Events
        </motion.h2>
        
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {events.map((event, index) => (
            <motion.div 
              key={index} 
              className="bg-gray-800 rounded-xl p-6 shadow-lg"
              variants={item}
              whileHover={{ 
                scale: 1.05, 
                boxShadow: "0 0 20px rgba(0, 255, 255, 0.3)",
                transition: { duration: 0.3 }
              }}
            >
              <div className="flex items-center mb-4">
                {event.type === 'zoom' && <Video className="w-8 h-8 mr-3 text-teal-400" />}
                {event.type === 'in-person' && <Users className="w-8 h-8 mr-3 text-teal-400" />}
                <h3 className="text-2xl font-semibold text-teal-400">{event.title}</h3>
              </div>
              <div className="flex items-center mb-2 text-gray-300">
                <Calendar className="w-5 h-5 mr-2 text-gray-400" />
                <p>{event.day}</p>
              </div>
              <div className="flex items-center text-gray-300">
                <Clock className="w-5 h-5 mr-2 text-gray-400" />
                <p>{event.time}</p>
              </div>
              <div className="mt-4 bg-teal-600 text-white text-sm font-semibold py-2 px-4 rounded-full inline-block">
                {event.type === 'zoom' ? 'Join on Zoom' : 'Attend in Person'}
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default EventsSection;