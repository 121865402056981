import React from 'react';
import { motion } from 'framer-motion';
import { Facebook, Instagram, Mail, Video } from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const socialLinks = [
    { icon: Facebook, href: "#", label: "Facebook" },
    { icon: Instagram, href: "#", label: "Instagram" },
    { icon: Video, href: "#", label: "Video Channel" },
    { icon: Mail, href: "#", label: "Email" },
  ];

  return (
    <footer className="bg-gray-900 text-gray-300 py-8 mb-2">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center md:flex-row md:justify-between md:items-center">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center md:text-left mb-4 md:mb-0"
          >
            <h3 className="text-2xl font-bold text-teal-400 mb-2">Rehoboth Church</h3>
            <p className="text-sm">Ethiopian Evangelical Church in Paris</p>
          </motion.div>
          
          <motion.div 
            className="flex space-x-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {socialLinks.map((link, index) => (
              <a 
                key={index} 
                href={link.href} 
                aria-label={link.label}
                className="text-gray-400 hover:text-teal-400 transition-colors duration-300"
              >
                <link.icon size={24} />
              </a>
            ))}
          </motion.div>
        </div>
        
        <motion.hr 
          className="border-gray-800 my-6"
          initial={{ opacity: 0, scaleX: 0 }}
          animate={{ opacity: 1, scaleX: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        />
        
        <motion.div 
          className="text-center text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <p>©{currentYear} Rehoboth • All Rights Reserved</p>
          <p className="mt-2">
            <a href="#" className="text-teal-400 hover:underline">Privacy Policy</a> | 
            <a href="#" className="text-teal-400 hover:underline ml-2">Terms of Service</a>
          </p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;