import React, { useState, useEffect } from 'react';

const Hero = () => {
  const [typedScripture, setTypedScripture] = useState('');
  const scripture = '"But as for me and my household, we will serve the Lord."';
  const typingSpeed = 100; // Typing speed per letter

  // Typing effect logic
  useEffect(() => {
    let charIndex = 0;
    const typeScripture = () => {
      if (charIndex < scripture.length) {
        setTypedScripture(scripture.slice(0, charIndex + 1));
        charIndex++;
        setTimeout(typeScripture, typingSpeed);
      }
    };
    typeScripture();
  }, []); // Empty dependency array ensures the effect runs once

  return (
    <section id="home" className="relative flex items-center justify-center min-h-screen text-white overflow-hidden">
      {/* Gradient Background */}
      <div 
        className="absolute inset-0 z-0" 
        style={{
          background: 'linear-gradient(45deg, #1a202c, #2d3748, #4a5568, #2d3748, #1a202c)',
          backgroundSize: '400% 400%',
          animation: 'gradient 15s ease infinite',
        }}
      ></div>

      {/* Content */}
      <div className="relative z-10 max-w-3xl mx-auto text-center px-4 sm:px-6 lg:px-8">
        {/* Scripture with typing effect */}
        <blockquote className="text-2xl sm:text-3xl md:text-4xl font-semibold italic text-teal-100 mb-6">
          <span className="whitespace-pre-line">{typedScripture}</span>
        </blockquote>
        <p className="text-lg sm:text-2xl font-medium text-teal-100 mb-8">
          Joshua 24:15
        </p>

        {/* Heading */}
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-8 animate-fade-in-up">
          Welcome to Rehoboth Church
        </h1>
        <p className="text-lg sm:text-xl text-gray-300 mb-8 animate-fade-in-up animation-delay-200">
          Ethiopian Evangelical Church in Paris, where faith and community unite under God's grace.
        </p>
      </div>

      {/* Bottom Gradient */}
      <div className="absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-gray-900 to-transparent"></div>
    </section>
  );
};

export default Hero;