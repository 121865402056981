import React from 'react';
import { motion } from 'framer-motion';
import { Users, MapPin, Calendar, Heart } from 'lucide-react';
import PhotoGallery from './PhotoGallery';

const About = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const features = [
    { icon: Users, text: "Vibrant Community", color: "text-blue-400" },
    { icon: MapPin, text: "Located in Paris", color: "text-green-400" },
    { icon: Calendar, text: "Regular Events", color: "text-purple-400" },
    { icon: Heart, text: "Committed to Service", color: "text-red-400" }
  ];

  return (
    <section id="about" className="py-16 bg-gradient-to-br from-gray-900 to-gray-800">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center text-white mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          About Us
        </motion.h2>
        
        <motion.div 
          className="flex flex-col items-center md:grid md:grid-cols-2 gap-12 mb-16"
          variants={fadeIn}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="text-center md:text-left">
            <motion.p 
              className="text-lg md:text-xl text-gray-300 mb-6 leading-relaxed"
              variants={fadeIn}
            >
              Welcome to Rehoboth Ethiopian Evangelical Church in Paris. 
              We are a vibrant community of believers committed to worship, fellowship, and service.
            </motion.p>
            <motion.p 
              className="text-lg md:text-xl text-gray-300 mb-6 leading-relaxed"
              variants={fadeIn}
            >
              Our church is a place where faith, culture, and community intersect, 
              providing a spiritual home for Ethiopian and Eritrean believers in Paris.
            </motion.p>
          </div>
          
          <motion.div 
            className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full max-w-md mx-auto md:max-w-none"
            variants={fadeIn}
          >
            {features.map((feature, index) => (
              <motion.div 
                key={index}
                className="bg-gray-800 p-6 rounded-xl shadow-lg flex flex-col items-center text-center"
                whileHover={{ scale: 1.05, boxShadow: "0 0 20px rgba(0, 255, 255, 0.3)" }}
                transition={{ duration: 0.3 }}
              >
                <feature.icon className={`${feature.color} mb-4`} size={48} />
                <p className="text-white font-semibold">{feature.text}</p>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
        
        <motion.div
          variants={fadeIn}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-center"
        >
          <h3 className="text-3xl md:text-4xl font-bold text-white mb-8">Our Gallery</h3>
          <PhotoGallery />
        </motion.div>
      </div>
    </section>
  );
};

export default About;