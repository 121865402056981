import React, { useState, useEffect, useRef } from 'react';
import { Mail, MapPin, Phone, ChevronUp, ChevronDown } from 'lucide-react';

const Contact = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const contactRef = useRef(null);
  const contentRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (contactRef.current) {
        const rect = contactRef.current.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0 && isExpanded) {
          setIsExpanded(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isExpanded]);

  useEffect(() => {
    if (contentRef.current) {
      if (isExpanded) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isExpanded]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => alert(`Copied: ${text}`)) // Optional: Show a success message
      .catch(err => console.error('Error copying text: ', err));
  };

  return (
    <section
      id="contact"
      ref={contactRef}
      className={`fixed bottom-0 w-full bg-gray-900 text-white rounded-t-3xl transition-all duration-500 ease-in-out z-50
        ${isExpanded ? 'shadow-lg' : 'shadow-sm'}`}
    >
      {/* Toggle Button with Chevron */}
      <div 
        className="flex items-center justify-center py-2 cursor-pointer"
        onClick={toggleExpand}
      >
        <div className="flex justify-between items-center w-12 h-1 bg-gray-500 rounded-full"></div>
        {isExpanded ? (
          <ChevronDown className="cursor-pointer w-6 h-6 ml-2 transition-transform duration-300" />
        ) : (
          <ChevronUp className="cursor-pointer w-6 h-6 ml-2 transition-transform duration-300" />
        )}
      </div>

      {/* Contact Details */}
      <div 
        ref={contentRef}
        className="overflow-hidden transition-all duration-500 ease-in-out"
        style={{ maxHeight: isExpanded ? '1000px' : '0px' }}
      >
        <div className="px-6 pb-6 py-1 sm:pb-2">
          <h2 className="text-lg font-semibold mb-4 text-center">Get In Touch</h2>
          <div className="flex flex-col items-center space-y-4 text-sm sm:text-base">
            {/* Email */}
            <div className="flex items-center space-x-2">
              <Mail className="w-5 h-5 text-teal-400" />
              <a href="mailto:info@rehobothchurch.com" className="text-sm text-gray-300 hover:text-white transition">
                info@rehobothchurch.com
              </a>
            </div>

            {/* Address */}
            <div className="flex items-center space-x-2">
              <MapPin className="w-5 h-5 text-teal-400" />
              <address className="cursor-pointer text-sm text-gray-300" onClick={() => copyToClipboard('185 rue des Pyrénées, 75020 Paris')}>
                185 rue des Pyrénées, 75020 Paris
              </address>
            </div>

            {/* Phone Numbers */}
            <div className="flex flex-col items-center space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4">
              <Phone className="w-5 h-5 text-teal-400" />
              <div className="flex flex-col items-center sm:flex-row sm:space-x-2">
                <span
                  onClick={() => copyToClipboard('+33 7 69 93 33 75')}
                  className="cursor-pointer text-sm text-gray-300 hover:text-white transition"
                >
                  +33 7 69 93 33 75
                </span>
                <span className="hidden sm:inline">|</span>
                <span
                  onClick={() => copyToClipboard('+33 6 28 68 73 62')}
                  className="cursor-pointer text-sm text-gray-300 hover:text-white transition"
                >
                  +33 6 28 68 73 62
                </span>
                <span className="hidden sm:inline">|</span>
                <span
                  onClick={() => copyToClipboard('+33 6 46 10 64 55')}
                  className="cursor-pointer text-sm text-gray-300 hover:text-white transition"
                >
                  +33 6 46 10 64 55
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
